<template>
  <div style="font-size: 0.36rem">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 1.6rem;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 0.4rem;
        z-index: 99;
        background-color: #fbf7f3;
      "
      :class="{ hyalineHeader: hyaline }"
    >
      <div style="display: flex; align-items: center">
        <div
          @click="onBack"
          v-if="back"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f7ede4;
            width: 0.64rem;
            height: 0.64rem;
            border-radius: 100%;
          "
          :class="{ hyalineIcon: hyaline }"
        >
          <van-icon
            name="arrow-left"
            size="0.36rem"
            color="#a1844c"
            style="font-weight: bold; color: #f7ede4; flex-shrink: 0"
          />
        </div>
        <div
          v-if="title"
          style="font-size: 0.5rem; color: #a1844c; font-weight: bold"
        >
          {{ title }}
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f7ede4;
          width: 0.64rem;
          height: 0.64rem;
          border-radius: 100%;
        "
        :class="{ hyalineIcon: hyaline }"
        @click="popupStatus = true"
      >
        <van-icon
          name="cart-o"
          size="0.36rem"
          :badge="
            cart.skuList
              ? cart.skuList.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.quantity,
                  0
                )
              : 0
          "
          color="#a1844c"
          style="font-weight: bold; color: #f7ede4; flex-shrink: 0"
        />
      </div>
    </div>
    <div v-if="!hyaline" style="height: 1.6rem"></div>
    <van-popup
      v-model="popupStatus"
      position="bottom"
      style="
        height: 80%;
        border-top-left-radius: 0.36rem;
        border-top-right-radius: 0.36rem;
      "
      closeable
    >
      <div
        style="
          font-size: 0.36rem;
          padding: 0.36rem;
          color: #a1844c;
          border-bottom: 1px solid #f7ede4;
        "
      >
        {{ $t("cart") }}
      </div>
      <!-- sku list -->
      <div style="padding: 0 0.36rem">
        <div
          v-for="(sku, skuIndex) in cart.skuList || []"
          :key="skuIndex"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.36rem;
          "
        >
          <div>
            <div style="font-size: 0.36rem; color: #a1844c">
              {{ sku.goodsName }}
            </div>
            <div style="font-size: 0.32rem; color: #666; margin-top: 8px">
              {{ sku.goodsSku }}
            </div>
            <div style="font-size: 0.32rem; color: #a1844c; margin-top: 8px">
              HK${{ sku.price }}
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                height: 34px;
                padding: 0 2px;
                border-radius: 21px;
                border: 1px solid #aa8439;
              "
            >
              <van-stepper
                theme="round"
                v-model="sku.quantity"
                @change="onModifyOrderCart(skuIndex)"
              />
            </div>
            <van-icon
              name="delete-o"
              size="0.4rem"
              color="#a1844c"
              style="
                font-weight: bold;
                color: #f7ede4;
                margin-left: 0.36rem;
                flex-shrink: 0;
              "
              class="activeOpacity"
              @click="onDeleteOrderCart(skuIndex)"
            />
          </div>
        </div>
        <div
          style="
            font-size: 0.36rem;
            color: #a1844c;
            display: flex;
            justify-content: space-between;
            margin-top: 0.64rem;
          "
        >
          <div style="color: #333">{{ $t("freight") }}</div>
          <div>
            {{ cart.freightMoney ? cart.freightMoney : $t("freeShipping") }}
          </div>
        </div>
        <div
          @click="onClickAddress"
          style="
            display: flex;
            color: #a1844c;
            display: flex;
            align-items: center;
            width: 100%;
            background: #f7ede4;
            margin-top: 0.64rem;
            padding: 0.28rem;
            border-radius: 4px;
          "
        >
          <van-icon
            name="location-o"
            size="0.4rem"
            color="#a1844c"
            style="margin-right: 8px; flex-shrink: 0"
          />
          {{
            cart.receiveAddress
              ? `${cart.receiveAddress.provinceName}${cart.receiveAddress.cityName}${cart.receiveAddress.areaName}${cart.receiveAddress.addressDetail}`
              : $t("addShippingAddress")
          }}
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: end;
            margin-top: 0.36rem;
          "
        >
          <div>{{ $t("totalAmount") }}:</div>
          <div style="color: #a1844c; margin-left: 8px">
            HK${{ cart.totalMoney }}
          </div>
        </div>
        <div
          v-if="this.cart.skuList"
          style="width: 100%; padding: 0.36rem; box-sizing: border-box"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgb(209, 176, 111);
              border-color: rgb(209, 176, 111);
              box-shadow: 0 0.1rem 0.2rem 0 rgba(96, 70, 20, 0.5);
              color: #fff;
              font-size: 0.32rem;
              height: 44px;
              width: 100%;
              border-radius: 64px;
              box-sizing: border-box;
              margin-top: 0.64rem;
            "
            @click="onBuy"
          >
            {{ $t("checkout") }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  getOrderCart,
  modifyOrderCart,
  deleteOrderCart,
  createPayment,
} from "@/api/goods";
import { Toast } from "vant";
const cartActionKey = "cartActionKey";
const gotoAddress = "gotoAddress";
export default {
  name: "ShoppingHeader",
  data: function () {
    return {
      cart: {},
      popupStatus: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    back: {
      type: Boolean,
      default: false,
    },
    hyaline: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    let status = false;
    if (localStorage.getItem(cartActionKey) === gotoAddress) {
      status = true;
      localStorage.removeItem(cartActionKey);
    }
    this.loadOrderCart(status);
  },
  methods: {
    loadOrderCart(popupStatus) {
      getOrderCart().then((res) => {
        if (res.code !== 200) return;
        this.cart = res.data;
        if (popupStatus) this.popupStatus = popupStatus;
      });
    },

    onModifyOrderCart(index) {
      modifyOrderCart({
        id: this.cart.skuList[index].id,
        quantity: this.cart.skuList[index].quantity,
      }).then(() => {
        this.loadOrderCart();
      });
    },

    onDeleteOrderCart(index) {
      deleteOrderCart({
        id: this.cart.skuList[index].id,
      }).then(() => {
        this.loadOrderCart();
      });
    },

    onBack() {
      this.$router.replace("/shoppingHome");
    },
    onClickAddress() {
      localStorage.setItem(cartActionKey, gotoAddress);
      this.$router.push("/shoppingAddress");
    },
    async onBuy() {
      if (!this.cart.receiveAddress) {
        Toast(this.$t("addShippingAddress"));
        return;
      }
      // const returnUrl = `${window.location.origin}/shoppingOrderList`;
      const u = navigator.userAgent;
      // const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //Android
      const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //iOS
      try {
        const payRes = await createPayment({
          cancelUrl: `${window.location.origin}${window.location.pathname}?payStatus=fail`,
          sourceType: isIOS ? 2 : 1,
          memberReceiveAddressId: this.cart.receiveAddress.id,
          totalMoney: this.cart.totalMoney,
          successUrl: `${window.location.origin}${window.location.pathname}?payStatus=success`,
          cartIdList: this.cart.skuList.map((v) => v.id),
          productName: this.cart.skuList[0].goodsName,
        });
        if (payRes.code !== 200) {
          Toast(payRes.msg);
          return;
        }
        window.location.href = payRes.data.url;
      } catch (e) {
        Toast("error");
        console.log(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.hyalineHeader {
  background: none !important;
}
.hyalineIcon {
  background-color: #bbb !important;
}
.hyalineIcon /deep/ .van-icon {
  color: #fff !important;
}
/deep/ .van-stepper__plus {
  background: #a1844c;
}
/deep/ .van-stepper__minus {
  color: #a1844c;
  border: 1px solid #a1844c;
}
/deep/ .van-stepper__input {
  width: 40px !important;
}
.activeOpacity:active {
  opacity: 0.8;
}
</style>
