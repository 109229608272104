import request, { baseUrl } from "@/utils/request";

export function getGoodsBannerList() {
  return request({
    url: `${baseUrl.api}/goods/banner/list`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

export function getGoodsList(data) {
  console.log(data);
  return request({
    url: `${baseUrl.api}/goods/getGoodsList`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}
export function getGoodsDetail(params) {
  return request({
    url: `${baseUrl.api}/goods/getGoodsDetail`,
    method: "GET",
    headers: {
      needToken: true,
    },
    params,
  });
}

export function getOrderCart() {
  return request({
    url: `${baseUrl.api}/order/cart/get`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

export function getGoodsSku(data) {
  return request({
    url: `${baseUrl.api}/goods/getGoodsSku`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

export function addOrderCart(data) {
  return request({
    url: `${baseUrl.api}/order/cart/add`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

export function deleteOrderCart(data) {
  return request({
    url: `${baseUrl.api}/order/cart/delete`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

export function modifyOrderCart(data) {
  return request({
    url: `${baseUrl.api}/order/cart/modify`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

export function createPayment(data) {
  return request({
    url: `${baseUrl.api}/stripe/create-payment-checkout`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}
