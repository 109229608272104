<template>
  <div style="background-color: #fbf7f3; min-height: 100%">
    <ShoppingHeader ref="shoppingHeader" hyaline back />
    <div v-if="goodsData">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in goodsData.imgList" :key="index">
          <img :src="item" style="width: 100%" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div style="padding: 0.4rem 0.4rem 1.6rem" v-if="goodsData">
      <h4 style="font-size: 24px; color: #a1844c">
        {{ goodsData.goodsName }}
      </h4>
      <div
        style="
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 0.32rem;
          color: #333;
          margin-top: 8px;
        "
      >
        HK${{ goodsData.marketPrice
        }}<span
          v-if="goodsData.stock === 0"
          style="
            background: #ee0a24;
            color: #fff;
            border-radius: 4px;
            padding: 1px 4px;
            font-size: 8px;
          "
          >{{ $t("soldOut") }}</span
        >
      </div>
      <div style="font-size: 0.28rem; color: #666; margin-top: 0.64rem">
        {{ goodsData.goodsDescription }}
      </div>
      <div style="margin-top: 0.36rem">
        <div
          v-for="(sku, skuIndex) in goodsData.skuList"
          :key="skuIndex"
          style="display: flex; font-size: 0.36rem; margin-bottom: 0.36rem"
        >
          <div
            style="
              color: #a1844c;
              flex-shrink: 0;
              height: 0.64rem;
              line-height: 0.64rem;
            "
          >
            {{ sku.specName }}
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <div
              v-for="(spec, specIndex) in sku.specVOList"
              :key="specIndex"
              style="
                background: #f7ede4;
                color: #bcbb9d;
                padding: 0px 24px;
                height: 0.64rem;
                line-height: 0.64rem;
                margin: 0px 0 0.28rem 0.28rem;
                border-radius: 0.64rem;
              "
              :class="{ specActive: spec.active }"
              @click="onSelectSku(skuIndex, specIndex)"
            >
              {{ spec.specValueName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 0.36rem;
        box-sizing: border-box;
      "
    >
      <div
        v-if="buying"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.36rem;
          background-color: rgb(251, 247, 243);
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            padding: 0 0.36rem;
            border-radius: 21px;
            border: 1px solid #aa8439;
            color: #aa8439;
          "
          @click="buying = false"
        >
          {{ $t("cancel") }}
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            padding: 0 8px;
            border-radius: 21px;
            border: 1px solid #aa8439;
          "
        >
          <van-stepper theme="round" v-model="addForm.quantity" />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            padding: 0 0.36rem;
            border-radius: 21px;
            background: #aa8439;
            color: #fff;
          "
          @click="onBuy"
        >
          {{ $t("buy") }}
        </div>
      </div>
      <div
        v-else
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgb(209, 176, 111);
          border-color: rgb(209, 176, 111);
          box-shadow: 0 0.1rem 0.2rem 0 rgba(96, 70, 20, 0.5);
          color: #fff;
          font-size: 0.32rem;
          height: 44px;
          width: 100%;
          border-radius: 64px;
          box-sizing: border-box;
        "
        @click="buying = true"
      >
        {{ $t("addCart") }}
      </div>
    </div>
  </div>
</template>
<script>
import ShoppingHeader from "@/components/ShoppingHeader";
import { Toast, Dialog } from "vant";

import {
  getGoodsBannerList,
  getGoodsDetail,
  getGoodsSku,
  addOrderCart,
} from "@/api/goods";

export default {
  components: {
    ShoppingHeader,
  },
  data() {
    return {
      id: this.$route.params.id,
      // 地址列表是否加载完成
      isFinish: false,
      loading: true,
      goodsBannerList: [],
      goodsParams: {
        pageNum: 0,
        pageSize: 20,
      },
      goodsData: null,
      buying: false,
      addForm: {
        quantity: 1,
        skuId: 0,
        goodsId: 0,
      },
    };
  },
  created() {
    console.log(this.$route);
    switch (this.$route.query.payStatus) {
      case "fail":
        Dialog({
          title: this.$t("payFail"),
          confirmButtonText: this.$t("confirm"),
        });
        break;
      case "success":
        Dialog({
          title: this.$t("paySuccess"),
          message: this.$t("psySuccessMessage"),
          confirmButtonText: this.$t("confirm"),
          confirmButtonColor: "rgb(209, 176, 111)",
        });
    }
    // if (this.$route.query.payStatus === 'fail') {
    //   Dialog({
    //     title: this.$t("payFail"),
    //     confirmButtonText: this.$t("confirm"),
    //   });
    // }
    this.getGoodsBannerList();
    this.getGoodsData();
  },
  methods: {
    onGetGoodsSku() {
      const specValueIds = [];
      this.goodsData.skuList.map((v) =>
        v.specVOList.map((vv) => {
          if (vv.active) specValueIds.push(vv.specValueId);
        })
      );
      return getGoodsSku({
        goodsId: this.id,
        specValueIds,
      }).then((res) => {
        if (res.code === 200) {
          this.goodsData.marketPrice = res.data.marketPrice;
          this.goodsData.stock = res.data.stock;
        }
        return res;
      });
    },
    getGoodsData() {
      getGoodsDetail({
        goodsId: this.id,
      }).then((res) => {
        this.loading = false;
        if (res.code !== 200) return;
        const skuList = res.data.skuList.map((v) => {
          v.specVOList[0].active = true;
          return v;
        });
        console.log(skuList);
        this.goodsData = { ...res.data, skuList, stock: -1 };
        this.onGetGoodsSku();
      });
    },
    getGoodsBannerList() {
      getGoodsBannerList().then((res) => {
        this.isFinish = true;
        if (res.code !== 200) return;
        this.goodsBannerList = res.data;
      });
    },

    onSelectSku(i, ii) {
      const skuList = this.goodsData.skuList;
      skuList[i].specVOList = skuList[i].specVOList.map((v) => {
        v.active = false;
        return v;
      });
      skuList[i].specVOList[ii].active = true;
      this.goodsData.skuList = skuList;
      this.onGetGoodsSku();
    },
    async onBuy() {
      // const loading = Toast.loading({
      //   message: "loading...",
      //   forbidClick: true,
      // });
      // 获取skuid
      try {
        const skuRes = await this.onGetGoodsSku();
        console.log(skuRes);
        if (skuRes.code !== 200) {
          Toast(skuRes.msg);
          return;
        }
        const addRes = await addOrderCart({
          skuId: skuRes.data.id,
          goodsId: this.id,
          quantity: this.addForm.quantity,
        });
        if (addRes.code !== 200) {
          Toast(addRes.msg);
          return;
        }
        this.$refs.shoppingHeader.loadOrderCart(true);
        // loading.clear();
      } catch (e) {
        Toast("error");
        // loading.clear();
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.specActive {
  background: #a1844c !important;
  color: #fff !important;
}
/deep/ .van-stepper__plus {
  background: #a1844c;
}
/deep/ .van-stepper__minus {
  color: #a1844c;
  border: 1px solid #a1844c;
}
/deep/ .van-stepper__input {
  width: 80px;
}
</style>
